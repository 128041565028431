/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * September 2022
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton, IonCard, IonCardContent
} from '@ionic/react';
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";


class Support extends React.Component<{}> {


    render() {
        return (
            <IonPage>
                <WebHeader title={'Soporte'}
                           showCartButton={true}
                           showSearchButton={true}
                           showMenuButton={true}>

                </WebHeader>
                <IonContent fullscreen>
                    <IonGrid className={'h-100'}>
                        <IonRow className={"h-100 ion-justify-content-center"}>
                            <IonCol size-md="6" className={"flex-col ion-justify-content-between"}>
                                <IonCard className={'ion-no-margin'}>
                                    <IonCardContent>
                                        <h1>Soporte</h1>
                                        <br/>
                                            <p className={'ion-text-justify'}>Para soporte técnico de tu cuenta, retroalimentación, dudas o compras en la aplicación escríbenos a <a href={'mailto:comercio@nbio.mx'}>comercio@nbio.mx</a> </p>
                                            <p className={'ion-text-justify'}>o si lo prefieres visita nuestra página de <a href={'https://www.facebook.com/NBIO-Cuidado-de-la-Salud-107444454919635/'}>Facebook</a> para soporte por chat.</p>
                                            <p className={'ion-text-justify'}>Para soporte por teléfono marca al 55 7095 5437</p>
                                        <br/>
                                        <h1>¿Cómo eliminar mi cuenta?</h1>
                                        <br/>
                                        <p className={'ion-text-justify'}>Si deseas eliminar tu cuenta sigue estas instrucciones:</p>
                                        <ul>
                                            <li>
                                                Ve a perfil, luego presiona sobre 'Eliminar mi cuenta'.
                                            </li>
                                            <li>
                                                Confirma tu elección.
                                            </li>
                                            <li>
                                                Tu historial de compras y de navegación será eliminado con tu usuario. Toma en cuenta que para volver a usar Nbio necesitarás hacer un nuevo usuario.
                                            </li>
                                        </ul>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Support);
